// Variable overrides
// ----------------------------------------------------------------

// Colors

$body-bg:       #e4f2f6;
$body-color:    #01426a;

$primary:       #01426a;
$secondary:     #007dba;
$success:       #64ccc9;
$light:         $body-bg;

$blue:          #049fd9;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Spacing

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
  8: ($spacer * 5)
);


// Components

$border-radius:     .3rem;
$border-radius-lg:  .5rem;
$border-radius-sm:  .125rem;

$component-active-bg: $secondary;


// Links

$link-color: $secondary;
$link-hover-decoration: none;


// Typography

$font-size-base: .875rem; // ~14px
$font-size-lg: $font-size-base * 1.143;  // ~16px
$font-size-sm: $font-size-base * .9286;  // ~13px

$font-family-sans-serif: "Poppins", Helvetica, Arial, sans-serif;
$headings-font-weight: 700;

$line-height-lg:     1.8;

$font-weight-medium: 500;
$font-weight-bold:   600;
$font-weight-bolder: 600;

$lead-font-size:     $font-size-base * 1.4;

// Buttons

$input-btn-padding-y: .5rem; // used by $input-padding-y and $btn-padding-y

$btn-white-space: nowrap;

$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;

$btn-padding-y-lg: .7rem;
$btn-padding-x-lg: 1.2rem;


// Forms

$input-font-size: $font-size-base;
$input-btn-font-size: $font-size-base;

$input-color: #000;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius;

$input-box-shadow: inset 0 1px 3px rgba(#000, .08);

$input-btn-focus-color:       rgba($component-active-bg, .15);
$input-focus-border-color:    lighten($component-active-bg, 5%);

$input-placeholder-color: $gray-500;

$form-feedback-font-size: $font-size-sm;

$custom-control-gutter:                         .7rem;
$custom-control-spacer-x:                       1.2rem;
$custom-control-indicator-size:                 1.4rem;
$custom-checkbox-indicator-border-radius:       $border-radius;
$custom-control-indicator-checked-bg:           $blue;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-active-bg:            lighten($custom-control-indicator-checked-bg, 35%);
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;


// Shadows

$box-shadow: 0 0 1.5rem rgba($primary, .1);

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";


// Modals

// Padding applied to the modal body
$modal-inner-padding:               0 2rem 2rem 2rem;

$modal-content-border-width:        0;
$modal-content-border-radius:       $border-radius-sm;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .75;
$modal-header-border-width:         0;
$modal-footer-border-width:         0;
$modal-header-padding-y:            1.6rem;
$modal-header-padding-x:            2rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem;
$alert-padding-x:                   1.25rem;
$alert-margin-bottom:               map-get($spacers, 4);
$alert-border-radius:               $border-radius-sm;
$alert-link-font-weight:            $font-weight-normal;

$alert-bg-level:                    -11;
$alert-border-level:                0;
$alert-color-level:                 12;

// Close

$close-font-size:                   $font-size-base * 2.6;
$close-font-weight:                 300;


// Bootstrap files
// ----------------------------------------------------------------

@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
// @import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
// @import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
// @import "../../node_modules/bootstrap/scss/nav";
// @import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/media";
// @import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";


// Bootstrap overrides
// ----------------------------------------------------------------

.form-control {
  box-shadow: $input-box-shadow;
}

.modal-footer {
  padding: 2rem;
}

// Utilities
// Medixine custom utilities
// ----------------------------------------------------------------

// Font sizes
// $grid-breakpoints is Bootstrap's Sass map
// example: "font-size-xl font-size-md-3xl" xl fontsize is used below md-screens and 3xl on md-screens and above.

$font-sizes: (
  4xl: $font-size-base * 3.6,
  3xl: $font-size-base * 2.7,
  2xl: $font-size-base * 1.8,
  xl: $font-size-base * 1.4,
  lg: $font-size-base * 1.143,  // ~16px
  base: $font-size-base,        // ~14px
  sm: $font-size-base * .9286,  // ~13px
  xs: $font-size-base * 0.857,
  2xs: $font-size-base * 0.786,
  3xs: $font-size-base * 0.714
);

@each $name, $size in $font-sizes {
  .font-size-#{$name} {
    font-size: #{$size} !important;
  }
}

@each $name, $size in $font-sizes {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .font-size-#{$breakpoint}-#{$name} { 
        font-size: #{$size} !important;
      }
    }
  }
}


// Colors

// Light bg colors
@each $name, $color in $theme-colors {
  .bg-#{$name}-light {
    background-color: theme-color-level($name, -10);
  }
}

// Dark bg colors
@each $name, $color in $theme-colors {
  .bg-#{$name}-dark {
    background-color: theme-color-level($name, 5);
  }
}

// Line height

.line-height-xl {
  line-height: $line-height-lg * 1.2;
}
.line-height-lg {
  line-height: $line-height-lg;
}
.line-height-base {
  line-height: $line-height-base;
}

// Font weight

.font-weight-medium {
  font-weight: $font-weight-medium !important;
} 


// Custom styles
// ----------------------------------------------------------------

html {
  height:100%;
}
body {
  min-height:100%; 
  //position:relative;
  font-family: $font-family-sans-serif;
  font-size: $font-size-lg;
}

.fill-current {
  fill: currentColor;
  svg {
    fill: currentColor;
  }
}

.landing {

  h3 {
    font-size: map-get($font-sizes, 2xl);
    margin-bottom: $spacer;
  }
  h4 { 
    font-size: map-get($font-sizes, lg);
    line-height: $line-height-lg;
    margin-bottom: 0;
    font-weight: 600;
  }
  p {
    line-height: $line-height-lg;
  }
}

.landing-modules {
  h4 { 
    font-size: map-get($font-sizes, xl);
    margin-bottom: map-get($spacers, 1);
  }
}

.header-logo {
  width: 210px;
}

.landing-hero-section {
  background: $body-bg url("../Images/project/medixine-wave.svg") no-repeat;
  background-position: top center;
} 

.sote-title {
    transform: rotate(-3deg);
    font-family: 'Kalam', cursive;
    font-weight: 400;
    width: 12rem;
    margin-left: 0;
}

@include media-breakpoint-down(sm) {
  .sote-title {
    margin-left: (-$spacer * 4);
  }
}

@include media-breakpoint-down(sm) {
  .sote-arrow {
    transform: rotate(55deg) scale(.8);
  }
}

.hero-image {
  max-width: 50%;
  min-width: 50%;
}
@include media-breakpoint-up(md) {
  .hero-image {
    max-width: 100%;
    min-width: 368px;
  }
}

.logo-cloud {
  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
    height: auto;
    margin: 2.4rem;
  }
}

.quote-title {
    font-family: Georgia, cursive;
}

@include media-breakpoint-down(sm) {
  .sm-screen {
    max-width: 85%;
    height: auto;
  }
}

// uncomment to hide recaptcha, but check https://cloud.google.com/recaptcha-enterprise/docs/faq#id_like_to_hide_the_recaptcha_enterprise_badge_what_is_allowed
// .grecaptcha-badge { visibility: hidden; }


@include media-breakpoint-up(lg) {
  .sign-up-aside {
    background-image: linear-gradient(rgba($primary, 0.9), $primary), url(../Images/project/sign-up-aside-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .sign-up-content {
    border-radius: $border-radius-lg;
  }
  .sign-up-aside {
    border-radius: $border-radius-lg 0 0 $border-radius-lg;
  }
}

.sign-up-aside-offer { 
  border: 3px solid $success;
  border-radius: $border-radius-lg;
}

.col-form-label {
  font-weight: $font-weight-medium;
}

.powered-by {
  opacity: .5;
  &:hover {
    opacity: .6;
  }
}
